@font-face {
    src: local("Roboto"), url('@assets/font/Roboto_w300.woff2') format("woff2");
    font-family: "Roboto";
    font-weight: 300;
}

@font-face {
    src: local("Roboto"), url('@assets/font/Roboto.woff2') format("woff2");
    font-family: "Roboto";
    font-weight: normal;
}

@font-face {
    src: local("Roboto"), url('@assets/font/Roboto_w500.woff2') format("woff2");
    font-family: "Roboto";
    font-weight: 500;
}

@font-face {
    src: local("Roboto"), url('@assets/font/Roboto_bold.woff2') format("woff2");
    font-family: "Roboto";
    font-weight: bold;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 auto !important;
    float: none !important;
}

code {
    font-family: "Roboto", sans-serif;
}

html,
body,
#root {
    height: 100%;
}

#apelio_content_container li {
    color: #4b4b4b;
}


#apelio_content_container h2,
#apelio_content_container h3,
#apelio_content_container h4,
#apelio_content_container h5,
#apelio_content_container h6 {
    font-family: Source Sans Pro,
        Arial,
        Helvetica,
        sans-serif;
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    hyphens: auto;
    display: inline;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
}

#apelio_content_container h2 {
    background-color: var(--mui-palette-primary-variant2);
    padding: 4px 12px;
}

#apelio_content_container h3 {
    background-color: var(--mui-palette-primary-variant4);
    padding: 0px 8px;
}

#apelio_content_container h4 {
    background-color: var(--mui-palette-primary-variant4);
    padding: 0px 8px;
}

#apelio_content_container figure img {
    border: 5px solid var(--mui-palette-secondary-variant1);
}

#apelio_content_container figcaption {
    text-align: center;
    font-size: 18px;
    color: #A0A0A0;
}

#apelio_content_container figure {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.plakos-premium-offer-container * {
    max-width: 100%;
    height: auto;
}

.h5p_wrapper {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes iFrameAnimation {
    0% {
        transform: scale(1, 1);
    }

    15% {
        transform: scale(1.05, 1.05)
    }

    50% {
        transform: scale(0, 0.7);
        rotate: 0deg;
    }

    70% {
        transform: scale(1.05, 1.05);
        rotate: 1.5deg;
    }

    82% {
        rotate: -1.2deg;
    }

    89% {
        transform: scale(1, 1);
        rotate: 1deg;
    }

    95% {
        rotate: -0.5deg;
    }

    100% {
        rotate: 0deg;
    }
}